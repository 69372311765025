import { datadogRum } from '@datadog/browser-rum';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    datadogRum.init({
      applicationId: '136fce1c-d815-4f43-af1b-8cbe65116d39',
      clientToken: 'pubbb15696b4cc314cb612ad51b47876476',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'uat-residential',
      env:'uat',
      // Specify a version number to identify the deployed version of your application in Datad**>
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: ["https://uat.fabricut.com/furniture", (url) => url.startsWith("https://uat.fabricut.com/furniture")],
    });
  }
}
